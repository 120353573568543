import { lumber } from '@mc-alberta/lumber'
import MagnumKPI from '@mc-alberta/magnumkpi'
import uuid from 'uuid/v4'

import iframeReference from '../helpers/iframeReference'
import normalizeCustomInputData from '../helpers/normalizeCustomInputData'
import normalizeDpaLocale from '../helpers/normalizeDpaLocale'
import post from '../post'
import utils from '../utils'
import validate from '../validate'

import { mastercardSrcInitiatorIds } from '../values'

// default to 'the same SDK url as this script', or prod
let sdkScriptUrl

try {
  sdkScriptUrl = document.currentScript.src
} catch (e) {}

export default async function init({
  srcInitiatorId, // required
  srciDpaId, // required
  srciTransactionId, // optional
  dpaTransactionOptions, // required
  dpaData, // optional

  // private
  host, // host is 'private', not in spec

  /* eslint-disable camelcase */
  __ma_params: maCustomParams // is an object not in spec
}) {
  const { state } = this
  const isMastercardSrci = Object.values(mastercardSrcInitiatorIds).includes(srcInitiatorId)

  const traceId = setTraceId(isMastercardSrci, maCustomParams)
  // attempt to add iframe and bootstrap lumber in order to log params validation results
  const srcDomain = utils.getOrigin(host || sdkScriptUrl)

  // Determine if SDK points to Production
  state.isProduction = srcDomain === 'https://src.mastercard.com'

  const iframe = await iframeReference(srcDomain, traceId)

  const iframeConfig = {
    ref: iframe.contentWindow,
    message: 'mastercard.proxysend',
    domain: srcDomain
  }

  post.init({
    windowRef: iframe.contentWindow,
    domain: srcDomain
  })

  lumber.bootstrap({
    name: 'SDK',
    version: VERSION,
    traceId,
    iframeConfig,
    path: 'logging/remotelog',
    // Disable the automatic flushing of the lumber queue
    // and rely on the flush points setup for getSrcProfile,
    // checkout, unbindAppInstance and deleteCard.
    // Automatic flushing is disabled by introducing a long
    // enough delay (1hour)
    // 1000 milliseconds * 60 seconds * 60 minutes
    delay: 1000 * 60 * 60
  })

  this.kpiLogger = new MagnumKPI({
    iframeConfig,
    source: 'srcsdk.mastercard.js',
    namespace: 'src.kpi.srciSdk',
    traceId
  })

  // TODO: Before validations become blocking move validation prior to iframe loading
  // TODO: Refactor this param validation to ensure headers are available for lumber
  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'init'
    },
    state
  )

  dpaTransactionOptions = normalizeDpaLocale(dpaTransactionOptions)
  dpaTransactionOptions = normalizeCustomInputData(dpaTransactionOptions, state)

  validate.params({
    srcInitiatorId,
    srciDpaId,
    dpaTransactionOptions
  })

  const headers = buildConfigHeaders({
    srcInitiatorId,
    srciDpaId,
    srciTransactionId,
    traceId
  })

  state.init({
    headers,
    dpaTransactionOptions,
    dpaData,
    srcInitiatorId,
    traceId,
    initResolved: true,
    isMastercardSrci
  })
}

function buildConfigHeaders({ srcInitiatorId, srciDpaId, srciTransactionId, traceId }) {
  const headers = {
    'SRC-Client-Id': srcInitiatorId,
    'SRC-DSA-Id': srciDpaId, // we still send the header as 'DSA' for 0.9 APIs
    'x-src-trace-id': traceId
  }

  // only add SRCI-Transaction-Id header if param was provided during the SDK init call
  if (srciTransactionId) {
    headers['SRCI-Transaction-Id'] = srciTransactionId
  }

  return headers
}

function setTraceId(isMastercardSrci, maCustomParams) {
  let mastercardProvidedTraceId

  try {
    if (isMastercardSrci) {
      mastercardProvidedTraceId = maCustomParams.traceId
    }
  } catch (e) {}

  return typeof mastercardProvidedTraceId === 'string' ? mastercardProvidedTraceId : uuid()
}
