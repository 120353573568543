import messenger from '@mc-alberta/messenger'
import events from '../events.json'
import SRCError from './SRCError'
import remoteLogger from './helpers/remoteLogging'

let windowRef, domain

// cache SRC-Correlation-Id and SRC-Recognition-Token to headers
// so we can feed back to API request
function cacheHeaders(response, state = { headers: {} }) {
  const correlationIdRegex = /src-correlation-id/i
  const recognitionTokenRegex = /src-recognition-token/i
  const { headers = null } = response.data

  if (!headers) return

  for (const key in headers) {
    if (correlationIdRegex.test(key) && !state.headers['SRC-Correlation-Id']) {
      // cache for subsequent calls
      state.headers['SRC-Correlation-Id'] = headers[key]
    }

    if (recognitionTokenRegex.test(key)) {
      state.headers['SRC-Recognition-Token'] = headers[key]
    }
  }
}

const post = {
  // init post and cache domain and windowRef for all subsequent requests
  init(params) {
    if (!windowRef) {
      windowRef = params.windowRef
    }
    if (!domain) {
      domain = params.domain
    }
  },

  // send a message to the proxy iframe that makes actual XHR to API
  // IMPORTANT: communicator-frame has its own version set in package.json
  // For breaking changes, update the communicator-frame version to change the file path
  async send(message, state) {
    const response = await messenger
      .send(windowRef, 'mastercard.proxysend', message, { domain })
      .then((event) => event)
      .catch((err) => {
        // Note: This assumes that the HTTP errors are silenced by the iframe
        remoteLogger.error(
          {
            ...events.errors.SDK_TO_IFRAME_COMMUNICATION_ERROR,
            details: {
              message: 'Failed to communicate with the SDK iframe',
              error: err
            }
          },
          state
        )
        throw new SRCError({
          error: {
            status: 500,
            reason: 'REQUEST_TIMEOUT',
            message: 'Failed to communicate with the iframe'
          }
        })
      })

    cacheHeaders(response, state)

    // this is 'response.postMessageEventData.axiosResponseData'
    return response.data.data
  }
}

export default post
