import { DEFAULT_PROGRAM_ID, DEFAULT_PROGRAM_OPTIONS } from '../constants'
import transform from '../transform'
import complianceSettingsTransform from '../transform/complianceSettings'
import { MASTERCARD, REMEMBERED, UNKNOWN, WINDOW_PARAMS } from '../values'
import { findProfile } from './profile'

export {
  buildDCFMessage,
  clearSDKState,
  isMastercardDcf,
  findCard,
  getWindow,
  updateActionsToComplyWithEMVCO,
  watchClose
}

// public functions
function clearSDKState({ action = '' }) {
  const { state } = this

  // clear card token at the end of checkout
  // the presence of card token can cause a
  // failed checkout if the user attempts to checkout
  // with a pre-existing card
  if (state.cardToken) {
    state.cardToken = undefined
  }

  // clear consumer state for SWITCH_CONSUMER
  // action
  if (action === 'SWITCH_CONSUMER') {
    state.resetConsumerState()
  }
}

function updateActionsToComplyWithEMVCO(result) {
  // 'AUTH_SKIPPED' and 'AUTH_FAILED'
  // are not EMVCO compliant actions
  // No need to return since result is passed by reference
  if (['AUTH_SKIPPED', 'AUTH_FAILED'].includes(result.action)) {
    result.action = 'ADD_CARD'
  }
}

/**
 * Returns the initial data that will be sent to the DCF.
 */
function buildDCFMessage({
  maskedCard, // type: MaskedCard
  transactionOptions, // type: DsaTransactionOptions
  srciActionCode, // type: string?
  consumer, // type: Partial<Consumer>?
  complianceSettings,
  payloadTypeIndicatorCheckout, // type: string?
  recipientIdCheckout, // type: string?
  appInstance // type: AppInstance?
}) {
  const { state } = this
  const message = {
    // properties shared by Mastercard and Third Party Wallets
    srcCorrelationId: state.headers['SRC-Correlation-Id'],
    srcInitiatorId: state.headers['SRC-Client-Id'],
    srciTransactionId: state.headers['SRCI-Transaction-Id'],
    cardToken: state.cardToken,
    srcDpaId: state.headers['SRC-DSA-Id'], // 1.0 name
    dpaLocale: transactionOptions.dpaLocale, // 1.0 name
    dpaData: state.dpaData, // 1.0 format
    payloadTypeIndicatorCheckout,
    recipientIdCheckout
  }

  if (isMastercardDcf(maskedCard)) {
    const profile = findProfile.call(this, { maskedCard })

    // Mastercard-specific DCF parameters
    return {
      ...message,
      action: srciActionCode,
      idToken: profile?.authorization,
      /**
       * @deprecated use `programUserInterfaceOptions.programId` instead
       */
      programId: profile?.programUserInterfaceOptions?.programId || DEFAULT_PROGRAM_ID,
      recognitionStatus: state.recognizedIdToken ? REMEMBERED : UNKNOWN,
      maskedCard: transform.card.request(maskedCard), // 0.9 format
      maskedConsumer: profile?.maskedConsumer
        ? transform.consumer.request(profile.maskedConsumer)
        : undefined, // 0.9 format
      maskedShippingAddress: profile?.maskedShippingAddresses?.map(
        transform.shippingAddress.request
      ), // 0.9 format
      srcDsaId: state.headers['SRC-DSA-Id'], // 0.9 name
      dpaTransactionOptions: transactionOptions, // 1.0 format
      dsaLocale: transactionOptions.dpaLocale, // 0.9 name
      dsaTransactionOptions: transform.dpaTransactionOptions.request({
        ...transactionOptions,
        payloadTypeIndicatorCheckout
      }), // 0.9
      dsaData: transform.dpaData.request(state.dpaData, transactionOptions), // 0.9 -- not required in 1.0 TA review -- conditional in 1.1
      consumer,
      complianceSettings: complianceSettingsTransform(complianceSettings), // 1.0 format + 1.3 REMEMBER_ME complianceResource
      recognitionToken: state.headers['SRC-Recognition-Token'],
      appInstance: appInstance || state.appInstance,
      programUserInterfaceOptions: profile?.programUserInterfaceOptions || DEFAULT_PROGRAM_OPTIONS
    }
  }

  // third-party-wallet-specific DCF parameters
  return {
    ...message,
    ...buildTPWMessage.call(this, { maskedCard, consumer, transactionOptions })
  }
}

/**
 * Returns a subset of SDK-to-DCF parameters that are specific to third-party wallets.
 */
function buildTPWMessage({ maskedCard, consumer, transactionOptions }) {
  transactionOptions = transform.merchantCountryCode.request({ ...transactionOptions })

  return {
    action: 'NEW_USER',
    maskedCard,
    consumer,
    dpaTransactionOptions: {
      ...transactionOptions,
      ...(transactionOptions.paymentOptions && {
        paymentOptions: [transactionOptions.paymentOptions]
      })
    } // 1.3 format
  }
}

/**
 * Returns true if the given card is destined to checkout using Mastercard's DCF.
 */
function isMastercardDcf(maskedCard) {
  if (!maskedCard.dcf?.name) return true

  return maskedCard.dcf.name === MASTERCARD
}

// return the windowRef we already have,
// or open one if we dont have one
function getWindow({ dcfWindowRef, windowRef }) {
  return dcfWindowRef || windowRef || window.open(...WINDOW_PARAMS)
}

// TODO: Add unit test
// if they close the window, cancel the checkout
async function watchClose({ dcfWindowRef }) {
  return new Promise((resolve) => {
    const interval = window.setInterval(() => {
      if (!dcfWindowRef || dcfWindowRef.closed) {
        window.clearInterval(interval)
        resolve({ action: 'CANCEL' })
      }
    }, 200)
  })
}

// get the full masked card for the card id provided
function findCard({ srcDigitalCardId }) {
  const { state } = this
  const cards = state.profiles
    // first look for card in profile
    .reduce((cards, { maskedCards }) => cards.concat(maskedCards), [])
    // or for card in maskedCards (via enroll(), then checkout())
    .concat(state.maskedCards)

  return cards.find((card) => card.srcDigitalCardId === srcDigitalCardId)
}
