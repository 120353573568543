import post from './post'

const retryAfter = 2000

/**
 * recursiveEnroll
 *
 * Calls the /cards api and will retry on any 503 or 504 response.
 *
 * Will only try to make 3 total api calls if the 3 rd call fails
 * it will return the 503 or 504 response.All other responses are returned as normal.
 *
 * @private
 * @param {Object} message - mesage to send to iframe
 * @param {String} tries - recursion counter
 * @return {Promise<Object>}
 */
export default async function recursiveEnroll(message, state, tries = 0) {
  try {
    return await post.send(message, state)
  } catch (error) {
    if ([503, 504].includes(error.response && error.response.status) && tries < 2) {
      await retryTimer(retryAfter)
      return recursiveEnroll(message, state, ++tries)
    }

    return Promise.reject(error)
  }
}

function retryTimer(retryAfter) {
  return new Promise((resolve) => setTimeout(resolve, retryAfter))
}
