import type { ProgramUserInterfaceOptions } from './types/ProgramUserInterfaceOptions'

/** The default program ID. A profile enrolled in this program is mutable. */
export const DEFAULT_PROGRAM_ID = 'SRC'

/** Default program-level configuration for a profile. */
export const DEFAULT_PROGRAM_OPTIONS: ProgramUserInterfaceOptions = {
  programId: DEFAULT_PROGRAM_ID,
  profileUpdateAllowed: true,
  profileUpdateConsentRequired: false,
  profileTncManagedBySRC: true
}
